var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showFooter
    ? _c("div", { staticClass: "booking-footer" }, [
        _vm.currentStep == "selectDate"
          ? _c("div", [
              _c(
                "button",
                {
                  staticClass: "cta",
                  attrs: {
                    disabled: !_vm.isSearchParamsValid || _vm.processing,
                  },
                  on: { click: _vm.searchRoomRates },
                },
                [
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          !_vm.isSearchParamsValid
                            ? "Select Dates"
                            : "Select Room(s)"
                        ) +
                        " "
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm.processing
                ? _c("div", { staticClass: "processing" }, [
                    _vm._v("Checking"),
                    _c("br"),
                    _vm._v("Availability..."),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.currentStep == "selectRoom"
          ? _c("div", [
              _c("div", { staticClass: "step-2__bottom--number-accomm" }, [
                _vm.areRoomsSelected
                  ? _c(
                      "button",
                      {
                        staticClass: "cta",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.$emit("checkout")
                          },
                        },
                      },
                      [_vm._v("Checkout")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "processing" }, [
                  _vm._v(
                    _vm._s(this.selectedRoom.length) +
                      " Accommodation(s) Selected"
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.currentStep == "selectCheckout"
          ? _c("div", [
              _vm.processing
                ? _c("div", { staticClass: "processing" }, [
                    _vm._v("Processing..."),
                  ])
                : _c("div", { staticClass: "step-3__cta-section" }, [
                    _c(
                      "button",
                      {
                        staticClass: "cta",
                        attrs: { disabled: _vm.processingForm },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.bookNow()
                          },
                        },
                      },
                      [_vm._v("Book Now")]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "modify processing",
                        attrs: { href: "" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.$emit("go-to-tab", "selectRoom")
                          },
                        },
                      },
                      [_vm._v("Modify")]
                    ),
                  ]),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }