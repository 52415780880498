<template>
  <div class="w-step">
    <div class="loading" v-if="processing">
      <img
        src="https://admin.symphonyhotelmarketing.com/includes/images/loading.gif"
      />
    </div>
    <div class="step-2 clearfix">
      <div class="step-2__top" v-if="!processing">
        <div class="step-2__top--mkt">
          <div class="step-2__bottom--filter" v-if="this.loadRateSelect()">
            <label for="view-by">Select Rate</label>
            <select
              name="view-by"
              id="view-by"
              v-model="ratePlan"
              @change="changeRatePlanCode"
            >
              <option
                :data-selected="rate.default"
                v-for="rate in getRatePlans"
                :key="rate.code"
                :value="rate.code"
              >
                {{ rate.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="step-2__top--form">
          <div class="w-entry date">
            <label for="check-in">Arrival</label>
            <label for="check-out">Departure</label>
            <functional-calendar
              :sundayStart="true"
              :calendarRates="[]"
              :isModal="true"
              :isTypeable="true"
              dateFormat="mm/dd/yyyy"
              v-model="calendarData"
              :is-separately="true"
              :is-date-range="true"
              :is-multiple="true"
              :calendars-count="1"
            ></functional-calendar>
            <!-- <label>Arrival</label>
								<div class="step-2__top--date">{{ searchParams.arrival }}</div> -->
          </div>
          <!-- <div class="w-entry date">
							<label>Departure</label>
							<div class="step-2__top--date">{{ searchParams.departure }}</div>
							</div> -->
          <div class="w-entry">
            <label for="adults-step-1">Adults</label>
            <select name="adults-step-1" id="adults-step-1" v-model="adults" @change="reloadRoomRates()">
              <option :value="n" v-for="n in maxAdultsSelection" :key="n" >
                {{ n }}
              </option>
            </select>
          </div>
          <div class="w-entry">
            <label for="child-step-1">Children</label>
            <select name="child-step-1" id="child-step-1" v-model="children" @change="reloadRoomRates()">
              <option value="0">0</option>
              <option :value="n" v-for="n in maxChildrenSelection" :key="n">
                {{ n }}
              </option>
            </select>
          </div>
          <div class="w-entry">
            <label for="rooms-step-1">Rooms</label>
            <select
              name="rooms-step-1"
              id="rooms-step-1"
              v-model="rooms"
              @change="updateNumberOfRooms"
            >
              <option :value="n" v-for="n in maxRoomSelection" :key="n">
                {{ n }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="step-2__bottom" v-if="!processing ">
        <div class="step-2__bottom--results" v-if="availableRooms.length > 0">
          <ul class="available-rooms">
            <li
              v-for="_room in availableRooms"
              :key="_room.code"
              :class="_room.code == activeRoomCode ? 'active' : ''"
              @click="previewRoom(_room)|sendGTMEventViewItem(_room)" 
              tabindex="0"
            >
              <!-- <img :src="icePortal.getImages(_room.code, 'thumb')[0]" alt /> -->
              <div
                :style="
                  `background-image:url(` +
                  icePortal.getImages(_room.code, 'full')[0] +
                  `)`
                "
                class="room_image"
              ></div>
              <div class="step-2__bottom--results--info">
                <div class="room_name">{{ _room.name }}</div>
                <p>
                  <!-- {{ choiceBooking.getPricesByRateAndRoomType(searchParams.ratePlan, room.code).rate.Base.AmountBeforeTax }} / night</p> -->
                  ${{ _room.rate.AmountBeforeTax | round }} / night
                </p>
              </div>
              <span></span>
            </li>
          </ul>
          
        </div>
        <div v-else style="margin-top: 50px">No room available</div>
        <!-- Room Details -->
        <div class="step-2__bottom--select">
          <div class="tabs" v-if="searchParams.rooms > 1">
            <a
              href=""
              :class="activeRoom == idx ? 'active tabs__unit' : 'tabs__unit'"
              @click.prevent="setActiveRoom(idx)"
              v-for="(n, idx) in roomList"
              :key="idx"
            >
              <span class="selected" v-if="isRoomSelected(idx)"></span>
              Rm {{ idx + 1 }}
              <!-- <span class="remove-room" v-if="idx > 0" @click.stop.prevent="removeRoom(idx)">Remove Room</span> -->
            </a>
            <!-- <a href=""  class="tabs__add" @click.prevent="addRoom" v-if="searchParams.rooms < maxRoomSelection">Add Room</a> -->
          </div>

          <!-- <transition-group name="list" mode="out-in"> -->

          <div v-for="(_room, idx) in roomList" :key="_room.code + idx">
            <!-- <template v-if="typeof _room.code !== 'undefined' && activeRoom == idx"> -->
            <div class="step-2__room" v-if="activeRoom == idx && !error">
              <div class="step-2__room--image">
                <div class="photo-slider">
                  <VueSlickCarousel
                    v-bind="slickSettings"
                    v-if="icePortal.getImages(_room.code, 'full').length > 0"
                  >
                  <a
                    v-for="(image, key) in icePortal.getImages(
                      _room.code,
                      'full'
                    )"
                    :key="key"
                      v-bind:href="image"
                      v-bind:data-fancybox-group="_room.code + idx"
                      class="fancybox-booking"
                     > 
                      <div v-bind:style="{
                          'background-image': 'url(' + image + ')',
                        }"
                        class="photo-slider__each-img" >
                        <!-- <img :src="image"  /> -->
                      </div>
                      <div class="photo-slider__hover-state"><span class="hidden">{{ _room.name }} Gallery</span></div>
                    </a> 
                  </VueSlickCarousel>
                  <div v-else>
                    <img
                      src="https://frontend.tambo.site/assets/images/image-not-available.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div class="step-2__room--desc">
                <div class="room_name">{{ _room.name }}</div>
                <ul class="step-2__room--list">
                  <li>Max occupancy: {{ _room.max_occupancy }}</li>
                  <!-- <li>Max occupancy: {{ _room.max_occupancy }} </li> -->
                </ul>
                <div class="step-2__room--description" v-if="_room.description && _room.description.length > 0 ">
                  <span v-html="_room.description"></span>
                </div>
                <div class="step-2__room--amenities">
                  <div class="step-2__room--amenities-title">
                    Room Amenities
                  </div>
                  <ul>
                    <li v-for="(amenity, idx) in filterDuplicates(_room.amenities)" :key="idx">
                      {{ amenity.CodeDetail }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="step-2__room--image step-2__room--image-price">
                <div class="step-2__room--price">
                  <span>${{ _room.rate.AmountBeforeTax | round  }}</span> / night
                </div>
                <!-- <div class="step-2__room--price pulse-dot">
                  <span></span>8 Rooms Available
                </div> -->
              </div>
              <div class="step-2__room--desc text-right">
                <template v-if="searchParams.rooms > 1">
                  <!-- <div>
											<span>Guests per room</span>
											<div>
												<label for="">Adults</label>
												<select name="" id="" v-model="_room.adults">
												<option v-for="n in r.max_occupancy" :key="n">{{n}}</option>
												<option v-for="nr in _room.max_occupancy" :key="nr">{{ nr }}</option>
												</select>
											</div>
											<div>
												<label for="">Children</label>
												<select name="" id="" v-model="_room.children">
												<option>0</option>
												<option v-for="nr in _room.max_occupancy - _room.adults" :key="nr">{{ nr }}</option>
												</select>
											</div>                    
											</div> -->
                  <div v-if="searchParams.rooms > 1 && !currentSelected">
                    <button
                      :disabled="currentSelected"
                      class="cta"
                      @click.prevent="selectRoom(_room)"
                      :class="currentSelected ? 'currentSelected' : ''"
                      @click="sendGTMEventSelectRoom(_room)"
                    >
                      {{ currentSelected ? "Selected" : "Select Room" }}
                    </button>
                  </div>
                  <button
                    class="cta"
                    v-if="areRoomsSelected"
                    @click.prevent="$emit('checkout')"
                  >
                    Checkout
                  </button>
                </template>
                <button
                  v-if="searchParams.rooms == 1"
                  class="cta"
                  @click.prevent="
                    selectRoom(_room);
                    currentStep = 'selectCheckout';
                  "
                  @click="sendGTMEventSelectRoom(_room)"
                >
                  Book Room
                </button>
              </div>
            </div>
            <!-- </template> -->
          </div>
          <!-- </transition-group> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChoiceBooking from "@/classes/ChoiceBooking";
import { FunctionalCalendar } from "@/modules/vue-functional-calendar";
import { BookingWidgetConfig } from "../config";
import VueSlickCarousel from "vue-slick-carousel";

import { bookingWidgetMixins } from '../mixins/bookingWidgetMixins'
import { calendarMixins } from '../mixins/calendarMixins'

import { mapActions, mapGetters } from "vuex";

export default {
  name: "SelectRoomDesktop",
  components: {
    FunctionalCalendar,
    VueSlickCarousel,
  },
  mixins: [ bookingWidgetMixins, calendarMixins ],
  props: ["icePortal"],
  watch: {
    calendarData: {
      handler(newVal) {
        // refresh rates on calendarData change
        if (newVal.dateRange.start.length > 0 && newVal.dateRange.end.length > 0) this.reloadRoomRates()
      },
      deep: true,
    },
  },

  created() {
    this.ratePlan = this.getRatePlans[0].code;
    //this.rateName = this.getRatePlans[0].name;

    //If promocode is passed, apply it
    if (this.promoCode.length > 0) {
      if (this.getRatePlans.find(rate => rate.code == this.promoCode) ) {
        this.ratePlan = this.promoCode;
        //this.rateName = rate.name;
      } else {
        console.info(`PromoCode ${this.promoCode} not found. Using default rate plan.`)
      }
    } 

    //get AvailableRooms
    this.availableRooms = this.getAvailableRooms() // this.choiceBooking.getAvailableRooms(this.activeRatePlan);

    this.selectDefaultRoom()


    
  },

  mounted(){
    $(".fancybox-booking").fancybox({
      wrapCSS : 'booking-gallery'
    });
    console.log("Lightbox mounted");
  },

  updated(){
    $(".fancybox-booking").fancybox({
      wrapCSS : 'booking-gallery'
    });
    console.log("Lightbox updated");
  },

  methods: {
    ...mapGetters(['getHotelName','getSelectedRooms']),

    // send datalayer to google with GA4 parameters
    sendGTMEventSelectRoom(room){

      if(this.$store.state.rateFullName == "") this.updateRateName();

      this.sendGTMwithGA4({
        event: "add_to_cart",
        items: [{
            item_id: room.code,
            item_name: room.name,
            affiliation: this.getHotelName(),
            currency: "USD",
            index: 0,
            item_brand: "Choice Hotels",
            item_category: this.ratePlan,
            item_category2: this.$store.state.rateFullName,
            price: room.rate.AmountBeforeTax,
            quantity: this.numberOfNights()
        }],
        arrival: this.$store.state.searchParams.arrival,
        departure: this.$store.state.searchParams.departure,
        adults: this.$store.state.searchParams.adults,
        children : this.$store.state.searchParams.children,
        rooms: this.$store.state.searchParams.rooms
      });
    },

    // send datalayer to google with GA4 parameters
    sendGTMEventViewItem(room){ 

      if(this.$store.state.rateFullName == "") this.updateRateName();
      
      this.sendGTMwithGA4({
        event: "view_item",
        items: [{
            item_id: room.code,
            item_name: room.name,
            affiliation: this.getHotelName(),
            currency: "USD",
            index: 0,
            item_brand: "Choice Hotels",
            item_category: this.ratePlan,
            item_category2: this.$store.state.rateFullName,
            price: room.rate.AmountBeforeTax,
            quantity: this.numberOfNights()
        }],
        arrival: this.$store.state.searchParams.arrival,
        departure: this.$store.state.searchParams.departure,
        adults: this.$store.state.searchParams.adults,
        children : this.$store.state.searchParams.children,
        rooms: this.$store.state.searchParams.rooms
      });
    }

  }

};



</script>