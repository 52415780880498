var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "w-step" }, [
    _vm.processing
      ? _c("div", { staticClass: "loading" }, [
          _c("img", {
            attrs: {
              src: "https://admin.symphonyhotelmarketing.com/includes/images/loading.gif",
            },
          }),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "step-2 clearfix" }, [
      !_vm.processing
        ? _c("div", { staticClass: "step-2__top" }, [
            _c("div", { staticClass: "step-2__top--mkt" }, [
              this.loadRateSelect()
                ? _c("div", { staticClass: "step-2__bottom--filter" }, [
                    _c("label", { attrs: { for: "view-by" } }, [
                      _vm._v("Select Rate"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.ratePlan,
                            expression: "ratePlan",
                          },
                        ],
                        attrs: { name: "view-by", id: "view-by" },
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.ratePlan = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            _vm.changeRatePlanCode,
                          ],
                        },
                      },
                      _vm._l(_vm.getRatePlans, function (rate) {
                        return _c(
                          "option",
                          {
                            key: rate.code,
                            attrs: { "data-selected": rate.default },
                            domProps: { value: rate.code },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(rate.name) +
                                "\n              "
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "step-2__top--form" }, [
              _c(
                "div",
                { staticClass: "w-entry date" },
                [
                  _c("label", { attrs: { for: "check-in" } }, [
                    _vm._v("Arrival"),
                  ]),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "check-out" } }, [
                    _vm._v("Departure"),
                  ]),
                  _vm._v(" "),
                  _c("functional-calendar", {
                    attrs: {
                      sundayStart: true,
                      calendarRates: [],
                      isModal: true,
                      isTypeable: true,
                      dateFormat: "mm/dd/yyyy",
                      "is-separately": true,
                      "is-date-range": true,
                      "is-multiple": true,
                      "calendars-count": 1,
                    },
                    model: {
                      value: _vm.calendarData,
                      callback: function ($$v) {
                        _vm.calendarData = $$v
                      },
                      expression: "calendarData",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "w-entry" }, [
                _c("label", { attrs: { for: "adults-step-1" } }, [
                  _vm._v("Adults"),
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.adults,
                        expression: "adults",
                      },
                    ],
                    attrs: { name: "adults-step-1", id: "adults-step-1" },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.adults = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        function ($event) {
                          return _vm.reloadRoomRates()
                        },
                      ],
                    },
                  },
                  _vm._l(_vm.maxAdultsSelection, function (n) {
                    return _c("option", { key: n, domProps: { value: n } }, [
                      _vm._v(
                        "\n                " + _vm._s(n) + "\n              "
                      ),
                    ])
                  }),
                  0
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "w-entry" }, [
                _c("label", { attrs: { for: "child-step-1" } }, [
                  _vm._v("Children"),
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.children,
                        expression: "children",
                      },
                    ],
                    attrs: { name: "child-step-1", id: "child-step-1" },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.children = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        function ($event) {
                          return _vm.reloadRoomRates()
                        },
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "0" } }, [_vm._v("0")]),
                    _vm._v(" "),
                    _vm._l(_vm.maxChildrenSelection, function (n) {
                      return _c("option", { key: n, domProps: { value: n } }, [
                        _vm._v(
                          "\n                " + _vm._s(n) + "\n              "
                        ),
                      ])
                    }),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "w-entry" }, [
                _c("label", { attrs: { for: "rooms-step-1" } }, [
                  _vm._v("Rooms"),
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.rooms,
                        expression: "rooms",
                      },
                    ],
                    attrs: { name: "rooms-step-1", id: "rooms-step-1" },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.rooms = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        _vm.updateNumberOfRooms,
                      ],
                    },
                  },
                  _vm._l(_vm.maxRoomSelection, function (n) {
                    return _c("option", { key: n, domProps: { value: n } }, [
                      _vm._v(
                        "\n                " + _vm._s(n) + "\n              "
                      ),
                    ])
                  }),
                  0
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.processing
        ? _c("div", { staticClass: "step-2__bottom" }, [
            _vm.availableRooms.length > 0
              ? _c("div", { staticClass: "step-2__bottom--results" }, [
                  _c(
                    "ul",
                    { staticClass: "available-rooms" },
                    _vm._l(_vm.availableRooms, function (_room) {
                      return _c(
                        "li",
                        {
                          key: _room.code,
                          class:
                            _room.code == _vm.activeRoomCode ? "active" : "",
                          attrs: { tabindex: "0" },
                          on: {
                            click: function ($event) {
                              _vm.previewRoom(_room) |
                                _vm.sendGTMEventViewItem(_room)
                            },
                          },
                        },
                        [
                          _c("div", {
                            staticClass: "room_image",
                            style:
                              `background-image:url(` +
                              _vm.icePortal.getImages(_room.code, "full")[0] +
                              `)`,
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "step-2__bottom--results--info" },
                            [
                              _c("div", { staticClass: "room_name" }, [
                                _vm._v(_vm._s(_room.name)),
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  "\n                  $" +
                                    _vm._s(
                                      _vm._f("round")(
                                        _room.rate.AmountBeforeTax
                                      )
                                    ) +
                                    " / night\n                "
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c("span"),
                        ]
                      )
                    }),
                    0
                  ),
                ])
              : _c("div", { staticStyle: { "margin-top": "50px" } }, [
                  _vm._v("No room available"),
                ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "step-2__bottom--select" },
              [
                _vm.searchParams.rooms > 1
                  ? _c(
                      "div",
                      { staticClass: "tabs" },
                      _vm._l(_vm.roomList, function (n, idx) {
                        return _c(
                          "a",
                          {
                            key: idx,
                            class:
                              _vm.activeRoom == idx
                                ? "active tabs__unit"
                                : "tabs__unit",
                            attrs: { href: "" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.setActiveRoom(idx)
                              },
                            },
                          },
                          [
                            _vm.isRoomSelected(idx)
                              ? _c("span", { staticClass: "selected" })
                              : _vm._e(),
                            _vm._v(
                              "\n              Rm " +
                                _vm._s(idx + 1) +
                                "\n              "
                            ),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.roomList, function (_room, idx) {
                  return _c("div", { key: _room.code + idx }, [
                    _vm.activeRoom == idx && !_vm.error
                      ? _c("div", { staticClass: "step-2__room" }, [
                          _c("div", { staticClass: "step-2__room--image" }, [
                            _c(
                              "div",
                              { staticClass: "photo-slider" },
                              [
                                _vm.icePortal.getImages(_room.code, "full")
                                  .length > 0
                                  ? _c(
                                      "VueSlickCarousel",
                                      _vm._b(
                                        {},
                                        "VueSlickCarousel",
                                        _vm.slickSettings,
                                        false
                                      ),
                                      _vm._l(
                                        _vm.icePortal.getImages(
                                          _room.code,
                                          "full"
                                        ),
                                        function (image, key) {
                                          return _c(
                                            "a",
                                            {
                                              key: key,
                                              staticClass: "fancybox-booking",
                                              attrs: {
                                                href: image,
                                                "data-fancybox-group":
                                                  _room.code + idx,
                                              },
                                            },
                                            [
                                              _c("div", {
                                                staticClass:
                                                  "photo-slider__each-img",
                                                style: {
                                                  "background-image":
                                                    "url(" + image + ")",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "photo-slider__hover-state",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "hidden" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(_room.name) +
                                                          " Gallery"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _c("div", [
                                      _c("img", {
                                        attrs: {
                                          src: "https://frontend.tambo.site/assets/images/image-not-available.jpg",
                                          alt: "",
                                        },
                                      }),
                                    ]),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "step-2__room--desc" }, [
                            _c("div", { staticClass: "room_name" }, [
                              _vm._v(_vm._s(_room.name)),
                            ]),
                            _vm._v(" "),
                            _c("ul", { staticClass: "step-2__room--list" }, [
                              _c("li", [
                                _vm._v(
                                  "Max occupancy: " +
                                    _vm._s(_room.max_occupancy)
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _room.description && _room.description.length > 0
                              ? _c(
                                  "div",
                                  { staticClass: "step-2__room--description" },
                                  [
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(_room.description),
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "step-2__room--amenities" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "step-2__room--amenities-title",
                                  },
                                  [
                                    _vm._v(
                                      "\n                    Room Amenities\n                  "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "ul",
                                  _vm._l(
                                    _vm.filterDuplicates(_room.amenities),
                                    function (amenity, idx) {
                                      return _c("li", { key: idx }, [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(amenity.CodeDetail) +
                                            "\n                    "
                                        ),
                                      ])
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "step-2__room--image step-2__room--image-price",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "step-2__room--price" },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "$" +
                                        _vm._s(
                                          _vm._f("round")(
                                            _room.rate.AmountBeforeTax
                                          )
                                        )
                                    ),
                                  ]),
                                  _vm._v(" / night\n                "),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "step-2__room--desc text-right" },
                            [
                              _vm.searchParams.rooms > 1
                                ? [
                                    _vm.searchParams.rooms > 1 &&
                                    !_vm.currentSelected
                                      ? _c("div", [
                                          _c(
                                            "button",
                                            {
                                              staticClass: "cta",
                                              class: _vm.currentSelected
                                                ? "currentSelected"
                                                : "",
                                              attrs: {
                                                disabled: _vm.currentSelected,
                                              },
                                              on: {
                                                click: [
                                                  function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.selectRoom(_room)
                                                  },
                                                  function ($event) {
                                                    return _vm.sendGTMEventSelectRoom(
                                                      _room
                                                    )
                                                  },
                                                ],
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.currentSelected
                                                      ? "Selected"
                                                      : "Select Room"
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.areRoomsSelected
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "cta",
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.$emit("checkout")
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    Checkout\n                  "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.searchParams.rooms == 1
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "cta",
                                      on: {
                                        click: [
                                          function ($event) {
                                            $event.preventDefault()
                                            _vm.selectRoom(_room)
                                            _vm.currentStep = "selectCheckout"
                                          },
                                          function ($event) {
                                            return _vm.sendGTMEventSelectRoom(
                                              _room
                                            )
                                          },
                                        ],
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  Book Room\n                "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ])
                      : _vm._e(),
                  ])
                }),
              ],
              2
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }