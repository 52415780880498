var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "w-step" }, [
    _c("div", { staticClass: "step-3" }, [
      _c("div", { staticClass: "summary" }, [
        _c("div", { staticClass: "step-3__title" }, [
          _vm._v("Your Stay Summary"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "summary__block" }, [
          _c("div", { staticClass: "summary__block--unit" }, [
            _c("span", [_vm._v("Arrival:")]),
            _vm._v(
              "\n          " + _vm._s(_vm.searchParams.arrival) + "\n        "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "summary__block--unit" }, [
            _c("span", [_vm._v("Departure:")]),
            _vm._v(
              "\n          " + _vm._s(_vm.searchParams.departure) + "\n        "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "summary__block" }, [
          _c("span", [_vm._v("Guest & Rooms:")]),
          _vm._v(
            "\n        " +
              _vm._s(_vm.searchParams.adults) +
              "\n        " +
              _vm._s(_vm.searchParams.adults > 1 ? "Adults" : "Adult") +
              ",\n        " +
              _vm._s(_vm.searchParams.children) +
              "\n        " +
              _vm._s(_vm.searchParams.children > 1 ? "Children" : "Child") +
              ",\n        " +
              _vm._s(_vm.searchParams.rooms) +
              "\n        " +
              _vm._s(_vm.searchParams.rooms > 1 ? "Rooms" : "Room") +
              "\n      "
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "summary__block" },
          _vm._l(_vm.selectedRoom, function (r, idx) {
            return _c(
              "div",
              {
                key: idx,
                class: !r.isValid
                  ? "summary__block--room error"
                  : "summary__block--room",
              },
              [
                _c("div", {
                  staticClass: "room_image",
                  style:
                    `background-image:url(` +
                    _vm.icePortal.getImages(r.code, "full")[0] +
                    `)`,
                }),
                _vm._v(" "),
                _c("div", { staticClass: "summary__block--room-summary" }, [
                  _c("span", { staticClass: "summary__block--room-name" }, [
                    _vm._v("Room " + _vm._s(r.name)),
                  ]),
                  _vm._v(" "),
                  _c("p", [_vm._v("Max Occupancy " + _vm._s(r.max_occupancy))]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "Amount before tax: $" + _vm._s(r.total.AmountBeforeTax)
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.searchParams.rooms > 1
                    ? _c(
                        "div",
                        { staticClass: "summary__block--room-guests" },
                        [
                          _c("div", [
                            _c("label", { attrs: { for: "summary-adults" } }, [
                              _vm._v("Adults"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: r.adults,
                                    expression: "r.adults",
                                  },
                                ],
                                attrs: {
                                  id: "summary-adults",
                                  name: "summary-adults",
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        r,
                                        "adults",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                    function ($event) {
                                      return _vm.validateRoom(r)
                                    },
                                  ],
                                },
                              },
                              _vm._l(r.max_occupancy, function (n) {
                                return _c("option", { key: n }, [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(n) +
                                      "\n                  "
                                  ),
                                ])
                              }),
                              0
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "error" }),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c(
                              "label",
                              { attrs: { for: "summary-children" } },
                              [_vm._v("Children")]
                            ),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: r.children,
                                    expression: "r.children",
                                  },
                                ],
                                attrs: {
                                  id: "summary-children",
                                  name: "summary-children",
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        r,
                                        "children",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                    function ($event) {
                                      return _vm.validateRoom(r)
                                    },
                                  ],
                                },
                              },
                              [
                                _c("option", [_vm._v("0")]),
                                _vm._v(" "),
                                _vm._l(
                                  r.max_occupancy - r.adults,
                                  function (n) {
                                    return _c("option", { key: n }, [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(n) +
                                          "\n                  "
                                      ),
                                    ])
                                  }
                                ),
                              ],
                              2
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _c("div", { staticClass: "summary__block" }, [
          _c("p", { staticClass: "summary__block--small" }, [
            _vm._v(
              "\n          * " +
                _vm._s(_vm.rateDetailsInfo.guarantee.text) +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "summary__block policy" }, [
            _c("span", [_vm._v("Rate Description")]),
            _vm._v(" "),
            _c("ul", [
              _c("li", [_vm._v(_vm._s(_vm.rateDetailsInfo.description))]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "summary__block policy" }, [
            _c("span", [_vm._v("Additional Details")]),
            _vm._v(" "),
            _c(
              "ul",
              _vm._l(
                _vm.rateDetailsInfo.additionalDetails,
                function (text, idx) {
                  return _c("li", { key: idx }, [_vm._v(_vm._s(text))])
                }
              ),
              0
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "summary__block policy" }, [
            _c("span", [_vm._v("Cancelation policy")]),
            _vm._v(" "),
            _c("ul", [
              _c("li", [
                _vm._v(
                  "Refundable: " +
                    _vm._s(
                      !_vm.rateDetailsInfo.cancelPenalties.NonRefundable
                        ? "Yes"
                        : "No" /*ratePlans[0].cancellation.text */
                    )
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(_vm._s(_vm.rateDetailsInfo.cancelPenalties.text)),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "summary__block policy" }, [
          _c("span", [_vm._v("Hotel Alerts")]),
          _vm._v(" "),
          _c(
            "ul",
            _vm._l(_vm.getHotelAlerts, function (el, idx) {
              return _c("li", { key: idx }, [_vm._v(_vm._s(el.text))])
            }),
            0
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "summary__block" }, [
          _c("div", { staticClass: "clearfix" }, [
            _c(
              "div",
              {
                staticClass:
                  "summary__total--label summary__total--label-small",
              },
              [_vm._v("Total (before tax)")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "summary__total--price" }, [
              _vm._v(
                "$" +
                  _vm._s(parseFloat(this.getTotalAmount().beforeTax).toFixed(2))
              ),
            ]),
          ]),
          _vm._v(" "),
          this.getTotalAmount().fees > 0
            ? _c("div", { staticClass: "clearfix" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "summary__total--label summary__total--label-small",
                  },
                  [_vm._v("Fees")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "summary__total--price" }, [
                  _vm._v(
                    "$" +
                      _vm._s(
                        (
                          parseFloat(this.getTotalAmount().fees) *
                          _vm.numberOfNights()
                        ).toFixed(2)
                      )
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "clearfix" }, [
            _c(
              "div",
              {
                staticClass:
                  "summary__total--label summary__total--label-small",
              },
              [_vm._v("Taxes")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "summary__total--price" }, [
              _vm._v(
                "$" + _vm._s(parseFloat(this.getTotalAmount().taxes).toFixed(2))
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "summary__total clearfix" }, [
          _c("div", { staticClass: "summary__total--label" }, [
            _vm._v("Subtotal"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "summary__total--price total" }, [
            _vm._v(
              "$" +
                _vm._s(parseFloat(this.getTotalAmount().afterTax).toFixed(2))
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.bookingStep == "Initiate"
        ? _c(
            "div",
            { staticClass: "guest-info", attrs: { id: "guestinfo" } },
            [
              _c("ValidationObserver", {
                ref: "observer",
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ invalid }) {
                        return [
                          _c("div", { staticClass: "step-3__title" }, [
                            _vm._v("Guest Information"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "flex" },
                            [
                              _c("ValidationProvider", {
                                staticClass: "w-entry w-entry-medium",
                                attrs: { rules: "required", tag: "div" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "hidden",
                                              attrs: {
                                                for: "first-name-payment",
                                              },
                                            },
                                            [_vm._v("First Name")]
                                          ),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.guestData.givenName,
                                                expression:
                                                  "guestData.givenName",
                                              },
                                            ],
                                            attrs: {
                                              id: "first-name-payment",
                                              name: "first-name-payment",
                                              type: "text",
                                              placeholder: "First Name",
                                            },
                                            domProps: {
                                              value: _vm.guestData.givenName,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.guestData,
                                                  "givenName",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(_vm._s(errors[0])),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _vm._v(" "),
                              _c("ValidationProvider", {
                                staticClass: "w-entry w-entry-medium",
                                attrs: { rules: "required", tag: "div" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "hidden",
                                              attrs: {
                                                for: "last-name-payment",
                                              },
                                            },
                                            [_vm._v("Last Name")]
                                          ),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.guestData.surName,
                                                expression: "guestData.surName",
                                              },
                                            ],
                                            attrs: {
                                              id: "last-name-payment",
                                              name: "last-name-payment",
                                              type: "text",
                                              placeholder: "Last Name",
                                            },
                                            domProps: {
                                              value: _vm.guestData.surName,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.guestData,
                                                  "surName",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(_vm._s(errors[0])),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _vm._v(" "),
                              _c("ValidationProvider", {
                                staticClass: "w-entry w-entry-large",
                                attrs: { rules: "email|required", tag: "div" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "hidden",
                                              attrs: {
                                                for: "email-address-payment",
                                              },
                                            },
                                            [_vm._v("Email Address")]
                                          ),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.guestData.email,
                                                expression: "guestData.email",
                                              },
                                            ],
                                            attrs: {
                                              id: "email-address-payment",
                                              name: "email-address-payment",
                                              type: "text",
                                              placeholder: "Email Address",
                                            },
                                            domProps: {
                                              value: _vm.guestData.email,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.guestData,
                                                  "email",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(_vm._s(errors[0])),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _vm._v(" "),
                              _c("ValidationProvider", {
                                staticClass: "w-entry w-entry-large",
                                attrs: { rules: "required", tag: "div" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "hidden",
                                              attrs: { for: "address-payment" },
                                            },
                                            [_vm._v("Address")]
                                          ),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.guestData.address1,
                                                expression:
                                                  "guestData.address1",
                                              },
                                            ],
                                            attrs: {
                                              id: "address-payment",
                                              name: "address-payment",
                                              type: "text",
                                              placeholder: "Address 1",
                                            },
                                            domProps: {
                                              value: _vm.guestData.address1,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.guestData,
                                                  "address1",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(_vm._s(errors[0])),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "w-entry w-entry-small" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "hidden",
                                      attrs: { for: "apt-suite-payment" },
                                    },
                                    [_vm._v("Apt/Suite")]
                                  ),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.guestData.address2,
                                        expression: "guestData.address2",
                                      },
                                    ],
                                    attrs: {
                                      id: "apt-suite-payment",
                                      name: "apt-suite-payment",
                                      type: "text",
                                      placeholder: "Apt/Suite",
                                    },
                                    domProps: { value: _vm.guestData.address2 },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.guestData,
                                          "address2",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c("ValidationProvider", {
                                staticClass:
                                  "w-entry w-entry-small city-payment",
                                attrs: { rules: "required", tag: "div" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "hidden",
                                              attrs: { for: "city-payment" },
                                            },
                                            [_vm._v("City")]
                                          ),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.guestData.city,
                                                expression: "guestData.city",
                                              },
                                            ],
                                            attrs: {
                                              name: "city-payment",
                                              id: "city-payment",
                                              type: "text",
                                              placeholder: "City",
                                            },
                                            domProps: {
                                              value: _vm.guestData.city,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.guestData,
                                                  "city",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(_vm._s(errors[0])),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _vm._v(" "),
                              _c("ValidationProvider", {
                                staticClass:
                                  "w-entry w-entry-small state-payment",
                                attrs: { rules: "required", tag: "div" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "hidden",
                                              attrs: { for: "state-payment" },
                                            },
                                            [_vm._v("State")]
                                          ),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.guestData.country !==
                                                  "US",
                                                expression:
                                                  "guestData.country !== 'US'",
                                              },
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.guestData.state,
                                                expression: "guestData.state",
                                              },
                                            ],
                                            attrs: {
                                              id: "state-payment",
                                              name: "state-payment",
                                              type: "text",
                                              placeholder: "State",
                                            },
                                            domProps: {
                                              value: _vm.guestData.state,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.guestData,
                                                  "state",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.guestData.country ==
                                                    "US",
                                                  expression:
                                                    "guestData.country == 'US'",
                                                },
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.guestData.state,
                                                  expression: "guestData.state",
                                                },
                                              ],
                                              on: {
                                                change: function ($event) {
                                                  var $$selectedVal =
                                                    Array.prototype.filter
                                                      .call(
                                                        $event.target.options,
                                                        function (o) {
                                                          return o.selected
                                                        }
                                                      )
                                                      .map(function (o) {
                                                        var val =
                                                          "_value" in o
                                                            ? o._value
                                                            : o.value
                                                        return val
                                                      })
                                                  _vm.$set(
                                                    _vm.guestData,
                                                    "state",
                                                    $event.target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "option",
                                                { attrs: { value: "" } },
                                                [_vm._v("State")]
                                              ),
                                              _vm._v(" "),
                                              _vm._l(
                                                _vm.usStates,
                                                function (state) {
                                                  return _c(
                                                    "option",
                                                    {
                                                      key: state.value,
                                                      domProps: {
                                                        value: state.value,
                                                      },
                                                    },
                                                    [_vm._v(_vm._s(state.text))]
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(_vm._s(errors[0])),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _vm._v(" "),
                              _c("ValidationProvider", {
                                staticClass: "w-entry w-entry-large",
                                attrs: { rules: "required", tag: "div" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "hidden",
                                              attrs: { for: "country-payment" },
                                            },
                                            [_vm._v("Country")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.guestData.country,
                                                  expression:
                                                    "guestData.country",
                                                },
                                              ],
                                              attrs: {
                                                name: "country-payment",
                                                id: "country-payment",
                                                placeholder: "Country",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$selectedVal =
                                                    Array.prototype.filter
                                                      .call(
                                                        $event.target.options,
                                                        function (o) {
                                                          return o.selected
                                                        }
                                                      )
                                                      .map(function (o) {
                                                        var val =
                                                          "_value" in o
                                                            ? o._value
                                                            : o.value
                                                        return val
                                                      })
                                                  _vm.$set(
                                                    _vm.guestData,
                                                    "country",
                                                    $event.target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "option",
                                                { attrs: { value: "" } },
                                                [_vm._v("Country")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "option",
                                                { attrs: { value: "US" } },
                                                [_vm._v("United States")]
                                              ),
                                              _vm._v(" "),
                                              _vm._l(
                                                Object.entries(_vm.countryList),
                                                function ([code, name]) {
                                                  return _c(
                                                    "option",
                                                    {
                                                      key: code,
                                                      domProps: {
                                                        value:
                                                          code.toUpperCase(),
                                                      },
                                                    },
                                                    [_vm._v(_vm._s(name))]
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(_vm._s(errors[0])),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _vm._v(" "),
                              _c("ValidationProvider", {
                                staticClass: "w-entry w-entry-small",
                                attrs: { rules: "required", tag: "div" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "hidden",
                                              attrs: { for: "zip-payment" },
                                            },
                                            [_vm._v("Zip Code")]
                                          ),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.guestData.postalCode,
                                                expression:
                                                  "guestData.postalCode",
                                              },
                                            ],
                                            attrs: {
                                              id: "zip-payment",
                                              name: "zip-payment",
                                              type: "text",
                                              placeholder: "Zip Code",
                                            },
                                            domProps: {
                                              value: _vm.guestData.postalCode,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.guestData,
                                                  "postalCode",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(_vm._s(errors[0])),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _vm._v(" "),
                              _c("ValidationProvider", {
                                staticClass: "w-entry w-entry",
                                attrs: {
                                  rules: "MemberIdValidator",
                                  tag: "div",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "hidden",
                                              attrs: {
                                                for: "choice-member-payment",
                                              },
                                            },
                                            [_vm._v("Membership Number")]
                                          ),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.guestData.memberNumber,
                                                expression:
                                                  "guestData.memberNumber",
                                              },
                                            ],
                                            attrs: {
                                              id: "choice-member-payment",
                                              name: "choice-member-payment",
                                              type: "text",
                                              placeholder: "Membership Number",
                                              maxlength: "12",
                                            },
                                            domProps: {
                                              value: _vm.guestData.memberNumber,
                                            },
                                            on: {
                                              keypress: function ($event) {
                                                return _vm.isAlphaNum($event)
                                              },
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.guestData,
                                                  "memberNumber",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(_vm._s(errors[0])),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "step-3__title" }, [
                            _vm._v("Payment Information"),
                          ]),
                          _vm._v(" "),
                          _c("ValidationProvider", {
                            staticClass: "w-entry w-entry-xlarge",
                            attrs: { rules: "required", tag: "div" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ errors }) {
                                    return [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "hidden",
                                          attrs: {
                                            for: "cardholder-name-payment",
                                          },
                                        },
                                        [_vm._v("Card Holder Name")]
                                      ),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.paymentData.card_holder,
                                            expression:
                                              "paymentData.card_holder",
                                          },
                                        ],
                                        attrs: {
                                          id: "cardholder-name-payment",
                                          name: "cardholder-name-payment",
                                          type: "text",
                                          placeholder: "Card Holder Name",
                                        },
                                        domProps: {
                                          value: _vm.paymentData.card_holder,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.paymentData,
                                              "card_holder",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("span", [_vm._v(_vm._s(errors[0]))]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "flex" },
                            [
                              _c("ValidationProvider", {
                                staticClass:
                                  "w-entry payment-required w-entry-large",
                                attrs: { rules: "required", tag: "div" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "hidden",
                                              attrs: { for: "card_number" },
                                            },
                                            [_vm._v("Card Number")]
                                          ),
                                          _vm._v(" "),
                                          _c("div", {
                                            staticClass: "form-field",
                                            attrs: { id: "cc-number" },
                                          }),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.paymentData.card_valid,
                                                expression:
                                                  "paymentData.card_valid",
                                              },
                                            ],
                                            ref: "ccNumberValid",
                                            attrs: {
                                              type: "hidden",
                                              name: "ccNumberValid",
                                              id: "ccNumberValid",
                                            },
                                            domProps: {
                                              value: _vm.paymentData.card_valid,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.paymentData,
                                                  "card_valid",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(_vm._s(errors[0])),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _vm._v(" "),
                              _c("ValidationProvider", {
                                staticClass:
                                  "w-entry payment-required w-entry-medium",
                                attrs: { rules: "required", tag: "div" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "hidden",
                                              attrs: {
                                                for: "exp-month-payment",
                                              },
                                            },
                                            [_vm._v("Expiration Month")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.paymentData
                                                      .expireDateMonth,
                                                  expression:
                                                    "paymentData.expireDateMonth",
                                                },
                                              ],
                                              attrs: {
                                                name: "exp-month-payment",
                                                id: "exp-month-payment",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$selectedVal =
                                                    Array.prototype.filter
                                                      .call(
                                                        $event.target.options,
                                                        function (o) {
                                                          return o.selected
                                                        }
                                                      )
                                                      .map(function (o) {
                                                        var val =
                                                          "_value" in o
                                                            ? o._value
                                                            : o.value
                                                        return val
                                                      })
                                                  _vm.$set(
                                                    _vm.paymentData,
                                                    "expireDateMonth",
                                                    $event.target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "option",
                                                {
                                                  attrs: {
                                                    value: "",
                                                    disabled: "",
                                                    selected: "",
                                                  },
                                                },
                                                [_vm._v("Exp Month")]
                                              ),
                                              _vm._v(" "),
                                              _vm._l(12, function (month) {
                                                return _c(
                                                  "option",
                                                  {
                                                    key: month,
                                                    domProps: {
                                                      value:
                                                        _vm._f("twoDigits")(
                                                          month
                                                        ),
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                " +
                                                        _vm._s(
                                                          _vm._f("toMonthName")(
                                                            month
                                                          )
                                                        ) +
                                                        "\n              "
                                                    ),
                                                  ]
                                                )
                                              }),
                                            ],
                                            2
                                          ),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(_vm._s(errors[0])),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _vm._v(" "),
                              _c("ValidationProvider", {
                                staticClass:
                                  "w-entry payment-required w-entry-medium",
                                attrs: { rules: "required", tag: "div" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "hidden",
                                              attrs: {
                                                for: "exp-year-payment",
                                              },
                                            },
                                            [_vm._v("Exp Year")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.paymentData
                                                      .expireDateYear,
                                                  expression:
                                                    "paymentData.expireDateYear",
                                                },
                                              ],
                                              attrs: {
                                                name: "exp-year-payment",
                                                id: "exp-year-payment",
                                                placeholder: "test",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$selectedVal =
                                                    Array.prototype.filter
                                                      .call(
                                                        $event.target.options,
                                                        function (o) {
                                                          return o.selected
                                                        }
                                                      )
                                                      .map(function (o) {
                                                        var val =
                                                          "_value" in o
                                                            ? o._value
                                                            : o.value
                                                        return val
                                                      })
                                                  _vm.$set(
                                                    _vm.paymentData,
                                                    "expireDateYear",
                                                    $event.target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "option",
                                                {
                                                  attrs: {
                                                    value: "",
                                                    disabled: "",
                                                    selected: "",
                                                  },
                                                },
                                                [_vm._v("Exp Year")]
                                              ),
                                              _vm._v(" "),
                                              _vm._l(
                                                _vm.yearsArray,
                                                function (year) {
                                                  return _c(
                                                    "option",
                                                    {
                                                      key: year.short,
                                                      domProps: {
                                                        value: year.short,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                " +
                                                          _vm._s(year.long) +
                                                          "\n              "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(_vm._s(errors[0])),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.processing
                            ? _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "step-3__processing" },
                                  [
                                    _c("div", {
                                      staticClass: "loader-booking",
                                    }),
                                    _vm._v("Processing..."),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$isMobile()
                            ? _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "step-3__cta-section" },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "modify",
                                        attrs: { href: "" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.$emit(
                                              "go-to-tab",
                                              "selectRoom"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("Modify")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "cta",
                                        attrs: {
                                          disabled: invalid || _vm.processing,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.bookNow()
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n              Book Now\n            "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3206478750
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.bookingStep == "Pending"
        ? _c("div", { staticClass: "w-step guest-info" }, [
            _c("ul", [
              _c("li", [
                _vm._v("First Name: " + _vm._s(_vm.bookingSummary.first_name)),
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v("Last Name: " + _vm._s(_vm.bookingSummary.last_name)),
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v("Checkin: " + _vm._s(_vm.bookingSummary.checkin)),
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v("Checkout: " + _vm._s(_vm.bookingSummary.checkout)),
              ]),
              _vm._v(" "),
              _c("li", [_vm._v("Adults:")]),
              _vm._v(" "),
              _c("li", [_vm._v("Children:")]),
              _vm._v(" "),
              _c("li", [_vm._v("Rooms:")]),
              _vm._v(" "),
              _c("li", [_vm._v("Total:")]),
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.confirmBooking.apply(null, arguments)
                  },
                },
              },
              [_vm._v("Confirm Booking")]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.bookingStep == "Committed"
        ? _c(
            "div",
            {
              staticClass: "guest-info guest-info__confirmation",
              attrs: { id: "divBookingConfirmation" },
            },
            [
              _c("div", { staticClass: "summary__guests" }, [
                _vm._m(0),
                _vm._v(" "),
                _c("ul", { staticClass: "step-3__confirmtion-info" }, [
                  _c("li", [
                    _c("span", [_vm._v("Confirmation Number")]),
                    _vm._v(
                      _vm._s(_vm.bookingSummary.reservationID) + "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("span", [_vm._v("Checkin")]),
                    _vm._v(
                      _vm._s(
                        _vm._f("formatDate")(_vm.bookingSummary.timespan.Start)
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("span", [_vm._v("Checkout")]),
                    _vm._v(
                      _vm._s(
                        _vm._f("formatDate")(_vm.bookingSummary.timespan.End)
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("span", [_vm._v("First Name")]),
                    _vm._v(
                      _vm._s(_vm.bookingSummary.customer.PersonName.GivenName)
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("span", [_vm._v("Last Name")]),
                    _vm._v(
                      _vm._s(_vm.bookingSummary.customer.PersonName.Surname)
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("ul", { staticClass: "step-3__list step-3__list--total" }, [
                _c("li", [
                  _c("span", [_vm._v("Total: ")]),
                  _vm._v(
                    "$" +
                      _vm._s(_vm.bookingSummary.total.amountAfterTax) +
                      "\n        "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "step-3__print-ctas" }, [
                _c("div", { staticClass: "step-3__print-ctas--reservations" }, [
                  _c(
                    "a",
                    {
                      staticClass: "cta step-3__print cta",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.printReservation.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("Print Reservation")]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "step-3__cancel",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.cancelReservation.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("Cancel Reservation")]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: { display: "none" },
                  attrs: { id: "divPrintPreview" },
                },
                [
                  _c("div", [
                    _c("h2", [_vm._v(_vm._s(_vm.getHotelName))]),
                    _vm._v(" "),
                    _c("h3", [_vm._v("Reservation Summary:")]),
                    _vm._v(" "),
                    _c("ul", {}, [
                      _c("li", [
                        _c("span", [_vm._v("Confirmation Number:")]),
                        _vm._v(_vm._s(_vm.bookingSummary.reservationID)),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("span", [_vm._v("First Name:")]),
                        _vm._v(
                          _vm._s(
                            _vm.bookingSummary.customer.PersonName.GivenName
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("span", [_vm._v("Last Name:")]),
                        _vm._v(
                          _vm._s(_vm.bookingSummary.customer.PersonName.Surname)
                        ),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("span", [_vm._v("Total: ")]),
                        _vm._v(
                          "$" + _vm._s(_vm.bookingSummary.total.amountAfterTax)
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("h3", [_vm._v("Stay Summary:")]),
                    _vm._v(" "),
                    _c("ul", {}, [
                      _c("li", [
                        _c("span", [_vm._v("Checkin:")]),
                        _vm._v(
                          _vm._s(
                            _vm._f("formatDate")(
                              _vm.bookingSummary.timespan.Start
                            )
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("span", [_vm._v("Checkout:")]),
                        _vm._v(
                          _vm._s(
                            _vm._f("formatDate")(
                              _vm.bookingSummary.timespan.End
                            )
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("span", [_vm._v("Guest & Rooms:")]),
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.searchParams.adults) +
                            "\n              " +
                            _vm._s(
                              _vm.searchParams.adults > 1 ? "Adults" : "Adult"
                            ) +
                            ",\n              " +
                            _vm._s(_vm.searchParams.children) +
                            "\n              " +
                            _vm._s(
                              _vm.searchParams.children > 1
                                ? "Children"
                                : "Child"
                            ) +
                            ",\n              " +
                            _vm._s(_vm.searchParams.rooms) +
                            "\n              " +
                            _vm._s(
                              _vm.searchParams.rooms > 1 ? "Rooms" : "Room"
                            ) +
                            "\n            "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("h3", [_vm._v("Room:")]),
                        _vm._v(" "),
                        _vm._l(_vm.selectedRoom, function (r, idx) {
                          return _c("div", { key: idx }, [
                            _c("img", {
                              attrs: {
                                src: _vm.icePortal.getImages(
                                  r.code,
                                  "thumb"
                                )[0],
                                alt: "",
                              },
                            }),
                            _vm._v(" "),
                            _c("ul", [
                              _c("li", [
                                _c("span", [_vm._v("Room ")]),
                                _vm._v(_vm._s(r.name)),
                              ]),
                              _vm._v(" "),
                              _c("li", [
                                _c("span", [_vm._v("Max Occupancy ")]),
                                _vm._v(" " + _vm._s(r.max_occupancy)),
                              ]),
                              _vm._v(" "),
                              _c("li", [
                                _c("span", [_vm._v("Amount before tax ")]),
                                _vm._v(" $" + _vm._s(r.total.AmountBeforeTax)),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", {}, [
                              _c("h4", [_vm._v("Additional Details")]),
                              _vm._v(" "),
                              _c(
                                "ul",
                                _vm._l(
                                  _vm.rateDetailsInfo.additionalDetails,
                                  function (text, idx) {
                                    return _c("li", { key: idx }, [
                                      _vm._v(_vm._s(text)),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", {}, [
                              _c("h4", [_vm._v("Cancelation policy")]),
                              _vm._v(" "),
                              _c("ul", [
                                _c("li", [
                                  _vm._v(
                                    "Refundable: " +
                                      _vm._s(
                                        !_vm.rateDetailsInfo.cancelPenalties
                                          .NonRefundable
                                          ? "Yes"
                                          : "No" /*ratePlans[0].cancellation.text */
                                      )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.rateDetailsInfo.cancelPenalties.text
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                          ])
                        }),
                      ],
                      2
                    ),
                  ]),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.bookingStep == "Cancelled"
        ? _c("div", { staticClass: "guest-info" }, [
            _c("p", [
              _vm._v("Your reservation has been cancelled successfully."),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v("Click "),
              _c(
                "a",
                {
                  attrs: { href: "" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.startOver.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("here to start over")]
              ),
              _vm._v(" or "),
              _c(
                "a",
                {
                  attrs: { href: "" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.close.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("close")]
              ),
              _vm._v(" this window."),
            ]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "step-3__title" }, [
      _vm._v("Your Reservation is "),
      _c("span", [_vm._v("Confirmed")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }