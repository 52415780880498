var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "top-widget" } },
    [
      _c(
        "button",
        {
          staticStyle: { display: "none" },
          attrs: { id: "btnShowBookingWidget" },
          on: { click: _vm.showBookingWidget },
        },
        [_vm._v("Book Now")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          attrs: { id: "btnClose", href: "#" },
          on: { click: _vm.closeBookingWidget },
        },
        [_c("span", { staticClass: "hidden" }, [_vm._v("close")])]
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.visible
          ? _c(
              "div",
              {
                staticClass: "booking-widget-container",
                attrs: { id: "booking-widget-container" },
              },
              [
                _c("div", { staticClass: "w-nav" }, [
                  _c("div", { staticClass: "container" }, [
                    _c(
                      "div",
                      {
                        staticClass: "w-nav__step",
                        class: _vm.currentStep == "selectDate" ? "active" : "",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.goToStep("selectDate")
                          },
                        },
                      },
                      [
                        _c(
                          "a",
                          { staticClass: "beBtnNav", attrs: { href: "#" } },
                          [_vm._v("Select Date")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "w-nav__step",
                        class: _vm.currentStep == "selectRoom" ? "active" : "",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.goToStep("selectRoom")
                          },
                        },
                      },
                      [
                        _c(
                          "a",
                          { staticClass: "beBtnNav", attrs: { href: "#" } },
                          [_vm._v("Select Room")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "w-nav__step",
                        class:
                          _vm.currentStep == "selectCheckout" ? "active" : "",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.goToStep("selectCheckout")
                          },
                        },
                      },
                      [
                        _c(
                          "a",
                          { staticClass: "beBtnNav", attrs: { href: "#" } },
                          [_vm._v("Checkout")]
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.currentStep == "selectDate"
                  ? _c("select-dates", {
                      on: { selectRoomBtnClicked: _vm.selectRoom },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.currentStep == "selectRoom" && !_vm.$isMobile()
                  ? _c("select-room-desktop", {
                      attrs: { icePortal: _vm.icePortal },
                      on: {
                        noRoomAvailable: _vm.noRoomAvailable,
                        checkout: _vm.checkout,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.currentStep == "selectRoom" && _vm.$isMobile()
                  ? _c("select-room-mobile", {
                      attrs: { icePortal: _vm.icePortal },
                      on: {
                        noRoomAvailable: _vm.noRoomAvailable,
                        checkout: _vm.checkout,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.currentStep == "selectCheckout"
                  ? _c("checkout", {
                      attrs: { icePortal: _vm.icePortal },
                      on: {
                        "go-to-tab": _vm.goToStep,
                        close: _vm.close,
                        "start-over": _vm.startOver,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.$isMobile()
                  ? _c(
                      "div",
                      { staticClass: "booking-widget-footer clearfix" },
                      [
                        _c(
                          "div",
                          { staticClass: "booking-widget-footer__container" },
                          [
                            _c(
                              "div",
                              { staticClass: "booking-widget-footer__mkt" },
                              [_vm._v(" ")]
                            ),
                            _vm._v(" "),
                            _c(
                              "ul",
                              {
                                staticClass:
                                  "booking-widget-footer__reservations",
                              },
                              [
                                _c("li", [
                                  _c("span", [_vm._v("Reservations: ")]),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: `tel:+${_vm.hotelData.phone}`,
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.hotelData.phone))]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _c("span", [_vm._v("Check-in: ")]),
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("time")(_vm.hotelData.checkIn)
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _c("span", [_vm._v("Check-out: ")]),
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("time")(_vm.hotelData.checkOut)
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.$isMobile()
                  ? _c("FooterMobile", {
                      attrs: { currentStep: _vm.currentStep },
                      on: { checkout: _vm.checkout, "go-to-tab": _vm.goToStep },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }